import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import Layout from "../lib/components/Layout"

function Page({ location }) {
  return (
    <Layout
      title="Nexus Commercial Services | 404 Error"
      description="404 Error"
      pageUrl={location.pathname}
      noNav
      fixedButton="backToHome"
    >
      <div
        className="relative w-full h-full overflow-hidden"
      >
        <StaticImage
          src="../assets/images/cement.jpg"
          alt="Background"
          placeholder="none"
          loading="eager"
          className="w-full h-full bg-cover pointer-events-none grayscale"
        />
        {/* BG Overlay */}
        <div className="absolute top-0 left-0 w-full h-full bg-neutral-800 bg-opacity-50 mix-blend-multiply"></div>
        {/* Text Container */}
        <div className="grid grid-cols-6 items-center absolute top-20 w-full h-full h-page-p">
          <div className="col-span-4 relative w-auto h-full text-left uppercase flex flex-col justify-center items-start gap-2 lg:gap-6">
            <h1 className="px-4 py-2 lg:px-7 lg:py-5 w-auto bg-neutral-800/75 mix-blend-normal lg:mix-blend-screen backdrop-blur-2xl !font-normal text-neutral-200 text-2xl lg:text-4xl leading-loose tracking-wider">Oops you have visited a page that doesn't exist.</h1>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
